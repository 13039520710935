import { render, staticRenderFns } from "./Apparel.vue?vue&type=template&id=66735820&lang=pug"
import script from "./Apparel.vue?vue&type=script&lang=js"
export * from "./Apparel.vue?vue&type=script&lang=js"
import style0 from "./Apparel.vue?vue&type=style&index=0&id=66735820&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports