import { render, staticRenderFns } from "./slek.vue?vue&type=template&id=4384f384&scoped=true&lang=pug"
import script from "./slek.vue?vue&type=script&lang=js"
export * from "./slek.vue?vue&type=script&lang=js"
import style0 from "./slek.vue?vue&type=style&index=0&id=4384f384&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4384f384",
  null
  
)

export default component.exports