import { render, staticRenderFns } from "./sasscolor.vue?vue&type=template&id=a5ae6e6c&scoped=true&lang=pug"
import script from "./sasscolor.vue?vue&type=script&lang=js"
export * from "./sasscolor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5ae6e6c",
  null
  
)

export default component.exports